export const environment = {
  production: true,
  api: 'https://core-api.luxnet.io',
  fileStorePrefix: 'filestore',
  fileStoreDNS: 'https://core.luxnet.io',
  socketUrl: 'wss://core-api.luxnet.io',
  vapidPublicKey:
    'BDXK5qKvLnIgJzOEA-LY4R_llpXZ-TF7uBo91P5hKSiz3PJMYwdZSbfpylj7R3LJAWyibegYaov6XOrlZ2XHjB4',
  turnstileSiteKey: '0x4AAAAAAAO3ggPkWuwXH16c',
  googleClientId:
    '717781505090-v4u7am2hppd2huqb4kdr2db7v8ped29i.apps.googleusercontent.com',
};
